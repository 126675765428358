@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;100;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  zoom: .95;
}
.footer {
  padding: 0.3rem;
  background-color: rgb(255, 255, 255);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 2px 4px black;
}
table {
  border-spacing: 0;
  border: 2px solid #ededed;
  width: 100%;
}
table tr:last-child td {
  border-bottom: 0;
}

thead {
  background: #2f5e8c;
  color: darkslategrey;
}

th:nth-child(1) {
  border-right: 4px solid rgb(237, 237, 237);
}
th:nth-child(2) {
  border-right: 4px solid rgb(237, 237, 237);
}
/* 
th:nth-child(6) {
  border-right: 4px solid rgb(237, 237, 237);
}
th:nth-child(10) {
  border-right: 4px solid rgb(237, 237, 237);
} */

td:nth-child(1) {
  text-align: left;
  border-right: 4px solid rgb(237, 237, 237);
}

td:nth-child(2) {
  border-right: 4px solid rgb(237, 237, 237);
}

td:nth-child() {
  text-align: left;
}
tr:first-child th {
  color: white;
}

table th,
table td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
  position: relative;
  color: darkslategrey;
  text-align: center;
}
table th:last-child,
table td:last-child {
  border-right: 0;
}
table tr:nth-child(even) {
  background-color: #fafafa;
}

table th::before {
  position: absolute;
  right: 15px;
  top: 16px;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
table th.sort-asc::before {
  border-bottom: 5px solid #22543d;
}
table th.sort-desc::before {
  border-top: 5px solid #22543d;
}

.App {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-right: 20px;
}
.badge {
  background-color: #9ae6b4;
  color: black;
  margin-right: 4px;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.livebadge {
  
  background-color: #1a771a;
  border-radius: 50%;
  color: white;
  padding: 0.5em 0.75em;
  position: relative;
  font-size: 10px;
}

.livebadge2 {
  width: fit-content;
  display: inline-flex;
  background-color: red;
  border-radius: 50%;
  padding: 0.6em 0.6em 0.7em 0.6em;
  position: relative;
  font-size: 5px;
}

.livebadgeTotal {
  width: fit-content;
  display: inline-flex;
  background-color: red;
  border-radius: 50%;
  padding: 0.6em 0.6em 0.7em 0.6em;
  position: relative;
  font-size: 8px;
}

.pulsate::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: pulse 1s ease infinite;
  border-radius: 70%;
  border: 4px double #047004;
}

.pulsate2::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: pulse 1s ease infinite;
  border-radius: 14px;
  border: 4px double red;
}

@keyframes pulse {
  0% {
    transform: scale(.9);
    opacity: 1;
  }
  60% {
    transform: scale(1.1);
    opacity: 0.4;
  }
  100% {
    transform: scale(1.2);
    opacity: 0.1;
  }
}

.card {
  background: #ffffff;

  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  text-align: center;
  margin-inline: 9px;
}
.card-er {
  background: #ffffff;
  height: 90px;
  justify-content: center;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
  border-radius:4px;
  text-align: center;
}

.card-er {
  font-size: 14px;
}
.card-text {
  font-size: 14px;
}

.green-row {
  background-color: #d4edda; /* Light green */
}

.red-row {
  background-color: #f8d7da; /* Light red */
}

.orange-row {
  background-color: #fff3cd; /* Light orange */
}
.legend-container {
  display: flex;
  float: inline-end;
  flex-direction: row;
  justify-content: space-around;
  padding: 5px;

  background-color: #f0f0f058; /* Light grey background for the legend */
  border-radius: 4px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin: 5px;
}

.legend-color-box {
  width: 20px;
  height: 20px;
  margin-right: 6px;
  border-radius: 4px;
}

.green-box {
  background-color: #d4edda; /* Light green */
}

.red-box {
  background-color: #f8d7da; /* Light red */
}

.orange-box {
  background-color: #fff3cd; /* Light orange */
}

.no-color-box {
  background-color: #ffffff; /* White */
  border: 1px solid #000; /* Black border */
}