.bc {
  background-color: #064686;
  color: white;
}
.bt {
  color: black;
  background-color: black;
}
.bod {
  color: black;
  background-color: white;
}
.withFrame {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 10px 2px #d0d0d0;
  transition: 0.3s;
  padding: 20px;
}


.sd-table__row {
  background-color: #fafafa;
}

.sd-input {
  border: 2px solid rgb(136, 136, 136);
 
}
.sd-input--disabled {
  color: black;
  background-color: white;
  box-shadow: none;
  border: none;
  text-align: center;
}